import * as classNames from 'classnames';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

export interface Props {
  href: string;
  delay: number;
  open: boolean;
  onClick?: (event: SyntheticEvent<EventTarget>) => void;
  standby: boolean;
  onCollapseSidebar: () => void;
}

class InternalMenuItem extends React.PureComponent<Props, {}> {

  public render() {
    const { href,  children, onClick, delay, open, standby, onCollapseSidebar } = this.props;
    return (
      <div
      className={classNames(
        'menu-item u-type-heading',
        open ? 'is-visible' : 'is-hidden',
        standby && 'is-on-standby'
      )}
      style={{ transitionDelay: `${delay}s` }}
      >
        <span className="skin-heading-sizes">
          <Link to={href} scroll={el => {
            this.props.onCollapseSidebar()
            el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          }>
            <span className="menu-item__anchor__pos">
              <span className="menu-item__anchor__bullet" />
              <span className="menu-item__anchor__text">{children}</span>
            </span>
          </Link>
        </span>
      </div>
    );
  }
}

export default InternalMenuItem;
