import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { IStore } from './types';

const middlewares = [thunk];

export function configureStore(initialState?: IStore) {
  const createStoreWithMiddleware = composeWithDevTools(
    applyMiddleware(...middlewares)
  )(createStore);
  return createStoreWithMiddleware(rootReducer, initialState);
}

export default configureStore();
