import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { collapseById, expandById } from '../../actions';
import { IStore } from '../../store/types';
import { ID_COLLECTION } from '../../utils';

interface DispatchProps {
  expandById: typeof expandById;
  collapseById: typeof collapseById;
}

interface StateProps {
  expanded: Set<string>;
}

type Props = StateProps & DispatchProps;

class MenuButton extends React.PureComponent<Props, {}> {
  public render() {
    return (
      <button
        aria-label="menu-button"
        className={classNames('menu-button', this.isExpanded() && 'is-open')}
        onClick={this.toggleExpandable}
      >
        <div className="menu-button__burger">
          <span className="menu-button__burger__line menu-button__burger__line--top" />
          <span className="menu-button__burger__line menu-button__burger__line--middle" />
          <span className="menu-button__burger__line menu-button__burger__line--bottom" />
        </div>
      </button>
    );
  }

  private toggleExpandable = () => {
    const { expanded } = this.props;

    if (expanded.has(ID_COLLECTION.SIDEBAR)) {
      this.props.collapseById(ID_COLLECTION.SIDEBAR);
    } else {
      this.props.expandById(ID_COLLECTION.SIDEBAR);
    }
  };

  private isExpanded = () => {
    const { expanded } = this.props;

    return expanded.has(ID_COLLECTION.SIDEBAR);
  };
}

const mapStateToProps = ({ expanded }: IStore): StateProps => ({
  expanded,
});

const mapDispatchToProps = {
  expandById,
  collapseById,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
