import * as classNames from 'classnames';
import * as React from 'react';

export interface Props {
  darkMode?: boolean;
  visible: boolean;
}

class ColorSwatchTick extends React.PureComponent<Props, {}> {
  public render() {
    const { darkMode, visible } = this.props;

    return (
      <svg
        className={classNames(
          'color-swatch-tick',
          darkMode && 'color-swatch-tick--dark',
          visible && 'is-visible'
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 107 107"
      >
        <path
          className="color-swatch-tick__outer"
          d="M53.5,107C24,107,0,83,0,53.5S24,0,53.5,0S107,24,107,53.5S83,107,53.5,107z M53.5,5C26.76,5,5,26.76,5,53.5 S26.76,102,53.5,102S102,80.24,102,53.5S80.24,5,53.5,5z"
        />
        <path
          className="color-swatch-tick__inner"
          d="M50.48,70.53c-1.23,1.3-3.33,1.3-4.55,0L33.42,57.71c-1.23-1.3-1.23-3.31,0-4.61c1.23-1.3,3.33-1.3,4.55,0 l10.26,10.52l21.79-22.14c1.23-1.3,3.33-1.3,4.55,0c1.23,1.3,1.23,3.31-0.07,4.61L50.48,70.53z"
        />
      </svg>
    );
  }
}

export default ColorSwatchTick;
