import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getInitialBrandingAssets, setBrandSlug } from '../../actions';
import LoadingSpinner from '../../components/common/loading-spinner';
import Menu from '../../components/menu/menu';
import MenuButton from '../../components/menu/menu-button';
import { IAppState } from '../../reducers/types/app-state';
import { IAssets } from '../../reducers/types/assets';
import { ComponentTypes, TransformedData } from '../../reducers/types/components';
import { IStore } from '../../store/types';
import { getBrandSlug, MAX_COLLAPSED_HEIGHT } from '../../utils';

import ColorsSection from '../../components/sections/color-section';
import FooterSection from '../../components/sections/footer-section';
import HeroSection from '../../components/sections/hero-section';
import ImageTextSection from '../../components/sections/image-text-section';
import LogosSection from '../../components/sections/logos-section';
import TextSection from '../../components/sections/text-section';
import VideoTextSection from "../../components/sections/video-text-section";
import ScrollToTopOnMount from "../../scroll";
import {HashRouter} from "react-router-dom";

export interface ComponentNames {
  TEXT: typeof TextSection;
  IMAGE_TEXT_GROUP: typeof ImageTextSection;
  VIDEO_TEXT_GROUP: typeof VideoTextSection;
  LOGO: typeof LogosSection;
  COLOUR: typeof ColorsSection;
  FOOTER: typeof FooterSection;
}

const COMPONENT_NAMES: ComponentNames = {
  TEXT: TextSection,
  IMAGE_TEXT_GROUP: ImageTextSection,
  VIDEO_TEXT_GROUP: VideoTextSection,
  LOGO: LogosSection,
  COLOUR: ColorsSection,
  FOOTER: FooterSection,
};

export interface AppState {
  sideBarOpen: boolean;
  path: string;
}

interface StateProps {
  appState: IAppState;
  assets: Map<string, IAssets>;
  components: Map<string, ComponentTypes[]>;
}

const mapStateToProps = ({
    appState,
    assets,
    components,
  }: IStore): StateProps => ({
    appState,
    assets,
    components,
});

const mapDispatchToProps = {
  getInitialBrandingAssets,
  setBrandSlug,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class BrandPage extends React.PureComponent<Props, AppState> {
  public constructor(props: Props) {
    super(props);
  }

  public componentWillMount() {
    this.props.getInitialBrandingAssets(`/api/brand/`);
    // @ts-ignore
    this.setState({path: this.props.location.pathname})
  }

  public render() {
    const { appState: { loading, error }} = this.props;
    const assets = this.props.assets.get(this.state.path);
    const components = this.props.components.get(this.state.path);

    if (loading) {
      return <LoadingSpinner className="app-spinner" />;
    }

    if (!assets) {
      return <p>{error}</p>;
    }

    return (
      <div className={assets.custom_font && 'custom-font-applied'}>
        <ScrollToTopOnMount/>
        <MenuButton />
        <Menu />
        <HeroSection assets={assets} scrollTo="pageComponents" />

        <main id="pageComponents">{this.renderComponents()}</main>

        {assets.custom_styles && <style>{assets.custom_styles}</style>}
        {assets.custom_font && <style>{assets.custom_font}</style>}
      </div>
    );
  }

  private renderComponents() {
    const assets = this.props.assets.get(this.state.path);
    const components = this.props.components.get(this.state.path) || [];

    const componentsList = components.map((component: ComponentTypes) => {
      const Component = COMPONENT_NAMES[component.type];

      return (
        <Component
          key={component.id}
          data={this.transformAssetsData(component)}
          assets={assets}
        />
      );
    });

    return componentsList;
  }

  private transformAssetsData(asset: ComponentTypes): TransformedData {
    return {
      id: asset.id,
      type: asset.type,
      order: asset.order,
      title: asset.title,
      content: asset.content,
      colors: asset.colors,
      image: asset.image,
      image_alt_text: asset.image_alt_text,
      hero_image_alt_text: asset.hero_image_alt_text,
      logo_image_alt_text: asset.logo_image_alt_text,
      video: asset.video,
      thumbnail: asset.thumbnail,
      isLeft: asset.side === 'LEFT',
      file: asset.file,
      button_copy: asset.button_copy,
      contentLength: asset.content.length,
      contentLines: asset.content.split('\n').length,
      maxCollapsedHeight: MAX_COLLAPSED_HEIGHT,
      truncate: asset.truncate,
      css_identifier: asset.css_identifier,
      assetbank_logo_category_id: asset.assetbank_logo_category_id,
    };
  }
}

export default connector(BrandPage);
