import * as classNames from 'classnames';
import * as React from 'react';
import { SyntheticEvent } from 'react';

export interface Props {
  className?: string;
  onClick?: (event: SyntheticEvent<EventTarget>) => void;
  text: string;
}

class BackButton extends React.PureComponent<Props, {}> {
  public render() {
    const { text, onClick, className } = this.props;

    const classes = classNames(
      'back-button u-type-body u-type-body--strong',
      className
    );

    return (
      <button onClick={onClick} className={classes}>
        <div className="back-button__arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            className="back-button__arrow__icon"
          >
            <path d="M69.54,12.47a5,5,0,0,0-7.07,0h0l-34,34a5,5,0,0,0,0,7.08h0l34,34a5,5,0,0,0,7.25-6.9,1.53,1.53,0,0,0-.18-.18L39.06,50,69.53,19.55a5,5,0,0,0,0-7.08Z" />
          </svg>
        </div>
        <span className="back-button__text">{text}</span>
      </button>
    );
  }
}

export default BackButton;
