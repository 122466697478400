import * as classNames from 'classnames';
import * as React from 'react';

export interface Props {
  name: string;
  logo: string;
  onClick?: () => void;
  delay?: number;
}

export interface LocalState {
  cardHovered: boolean;
}

class LogoCard extends React.PureComponent<Props, LocalState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      cardHovered: false,
    };
  }

  public render() {
    const { name, logo, onClick, delay } = this.props;
    const { cardHovered } = this.state;

    const cardClasses = classNames(
      'logo-card skin-card-corners',
      cardHovered && 'is-hovered'
    );

    return (
      <div
        className={cardClasses}
        onClick={onClick}
        onMouseEnter={this.onCardEnter}
        onMouseLeave={this.onCardLeave}
        style={{ animationDelay: `${delay}s` }}
      >
        <div className="logo-card__display">
          <div className="logo-card__area">
            <div
              className="logo-card__area__logo"
              style={{ backgroundImage: `url("${logo}")` }}
            />
          </div>
          <div className="logo-card__foot">
            <h4 className="logo-card__foot__title u-type-body u-type-body--strong u-type-sm">
              {name}
            </h4>
          </div>
        </div>

        <div className="logo-card__download">
          <div className="logo-card__download__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111 149.5">
              <path
                className="logo-card__download__icon__circle"
                d="M55.5,149.5C24.9,149.5,0,124.6,0,94c0-30.6,24.9-55.5,55.5-55.5C86.1,38.5,111,63.4,111,94 C111,124.6,86.1,149.5,55.5,149.5z M55.5,43.5C27.65,43.5,5,66.15,5,94s22.65,50.5,50.5,50.5S106,121.85,106,94 S83.35,43.5,55.5,43.5z"
              />

              <path
                className="logo-card__download__icon__arrow"
                d="M71.57,91.49c-1.16-1.14-3.01-1.14-4.17,0l-8.92,8.24V7.98c0-1.72-1.51-2.98-3.01-2.98 c-1.74,0-3.01,1.49-3.01,2.98v91.75l-9.5-8.47c-0.58-0.57-1.16-0.92-2.09-0.92h-0.35c-0.93,0-1.51,0.34-2.09,0.92 c-0.58,0.57-0.93,1.49-0.93,2.06c0,0.92,0.35,1.49,0.93,2.06l14.6,12.93c1.16,0.92,3.01,0.92,4.17,0l14.02-12.93 c0.93-0.34,1.27-0.92,1.27-1.83C72.5,92.64,72.15,92.06,71.57,91.49z"
              />
            </svg>
          </div>

          <div className="logo-card__download__label u-type-body u-type-sm u-type-body--dark u-type-body--strong">
            Download
          </div>
        </div>
      </div>
    );
  }

  private onCardEnter = () => {
    this.setState({ cardHovered: true });
  };

  private onCardLeave = () => {
    this.setState({ cardHovered: false });
  };
}

export default LogoCard;
