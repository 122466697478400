import * as classNames from 'classnames';
import * as copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';

export interface Props {
  darkMode?: boolean;
  visible: boolean;
  showCopyConfirm: () => void;
  updateCopyValue: (value: string) => void;
  colors: {
    [key: string]: string | undefined;
    hex: string;
    cmyk?: string;
    rgb?: string;
    pantone?: string;
  };
}

export interface LocalState {
  listInteracting: boolean;
}

class ColorSwatchFormats extends React.PureComponent<Props, LocalState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      listInteracting: false,
    };
  }

  public render() {
    const { visible, darkMode } = this.props;
    const { listInteracting } = this.state;

    return (
      <div
        className={classNames(
          'color-swatch-formats',
          !darkMode && 'color-swatch-formats--light',
          visible && 'is-active',
          listInteracting && 'is-on-standby'
        )}
      >
        <ul className={classNames('color-swatch-formats__list')}>
          {this.renderFormats(['pantone', 'cmyk', 'rgb', 'hex'])}
        </ul>
      </div>
    );
  }

  private renderFormats(colorOrder: string[]) {
    return colorOrder.map((format, index) => {
      const label = format;
      const value = this.props.colors[format];
      const presentationValue = format === 'hex' ? `#${value}` : value;
      const { darkMode } = this.props;

      // Useful to add affordance to the hex option in the future
      const hexHeightPercentage = 25;
      const height =
        format === 'hex'
          ? `${hexHeightPercentage}%`
          : `${(100 - hexHeightPercentage) / 3}%`;

      if (value) {
        return (
          <li
            className="color-swatch-formats__item"
            key={index}
            onClick={this.onCopyToClipboard.bind(this, value)}
            onMouseEnter={this.listIsInteracting}
            onMouseLeave={this.listIsIdle}
            style={{ height }}
          >
            <h4
              className={classNames(
                'color-swatch-formats__heading u-type-label u-type-xs',
                darkMode && 'u-type-label--dark'
              )}
            >
              <span>{label}</span>
              <span className="color-swatch-formats__heading__value">
                {presentationValue}
              </span>
            </h4>
            <span
              className="color-swatch-formats__item__bg"
              style={{ backgroundColor: `#${this.props.colors.hex}` }}
            />
          </li>
        );
      }
    });
  }

  private listIsInteracting = () => {
    this.setState({ listInteracting: true });
  };

  private listIsIdle = () => {
    this.setState({ listInteracting: false });
  };

  private onCopyToClipboard = (value: string) => {
    copyToClipboard(value);

    this.props.showCopyConfirm();
    this.props.updateCopyValue(value);
  };
}

export default ColorSwatchFormats;
