import {
  GET_INITIAL_BRANDING_LOGOS,
  LOAD_BRANDING_LOGOS,
  UPDATE_INITIAL_BRANDING_LOGOS,
} from '../actions';
import { IAction } from '../actions';
import { RADIO_OPTION_NOT_SELECTED } from '../components/common/logo-wizard';
import { LogoState } from './types/logos';

export const defaultLogoState: LogoState = {
  question: '',
  options: [],
  selectedID: RADIO_OPTION_NOT_SELECTED,
  history: [],
  isLogoLoaded: false,
  logos: [],
  radioChecked: [],
  folders: null,
  isDataFound: false,
  loading: false,
};

const initialState = new Map<string, LogoState>();

export function logos(state = initialState, action: IAction<any, any>) {
  let assetbank_logo_category_id: string;
  if (action.payload) {
    assetbank_logo_category_id = action.payload.assetbank_logo_category_id;
  } else {
    assetbank_logo_category_id = '';
  }
  const logoState = state.get(assetbank_logo_category_id) || {
    ...defaultLogoState,
  };

  switch (action.type) {
    case GET_INITIAL_BRANDING_LOGOS.SUCCESS:
      const question = action.payload.description;
      const options = action.payload.options;
      return new Map(
        state.set(assetbank_logo_category_id, {
          ...logoState,
          question,
          options,
          history: [...logoState.history, ...action.payload],
          folders: action.payload,
          isDataFound: true,
          loading: false,
        })
      );

    case GET_INITIAL_BRANDING_LOGOS.REQUEST:
      return new Map(
        state.set(assetbank_logo_category_id, {
          ...logoState,
          loading: true,
        })
      );

    case UPDATE_INITIAL_BRANDING_LOGOS:
      return new Map(
        state.set(assetbank_logo_category_id, {
          ...logoState,
          ...action.payload,
        })
      );

    case LOAD_BRANDING_LOGOS.SUCCESS:
      delete action.payload.assetbank_logo_category_id;
      return new Map(
        state.set(assetbank_logo_category_id, {
          ...logoState,
          logos: Object.values(action.payload),
          loading: false,
          isLogoLoaded: true,
          history: [
            ...logoState.history,
            ...[
              {
                description: 'LogosLoaded',
                options: [],
                id: -1,
                name: '',
              },
            ],
          ],
        })
      );

    case LOAD_BRANDING_LOGOS.FAILURE:
      return new Map(
        state.set(assetbank_logo_category_id, {
          ...logoState,
          loading: false,
          isLogoLoaded: false,
        })
      );

    case GET_INITIAL_BRANDING_LOGOS.FAILURE:
      return new Map(
        state.set(assetbank_logo_category_id, {
          ...logoState,
          loading: false,
          isDataFound: false,
        })
      );

    default:
      return state;
  }
}
