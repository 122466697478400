import { throttle } from 'lodash';
import * as path from 'path';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import * as urlParse from 'url-parse';
import { IAssets } from '../../reducers/types/assets';
import { removeWidowWord } from '../../utils';
import ActionButton from './../common/action-button';

export interface Props {
  assets: IAssets;
  scrollTo: string;
}

interface LocalState {
  fullScreen: boolean;
}

class HeroSection extends React.PureComponent<Props, LocalState> {
  private detailsElement?: HTMLDivElement;

  public constructor(props: Props) {
    super(props);

    this.state = {
      fullScreen: false,
    };

    this.updateDimensions = throttle(this.updateDimensions.bind(this), 200);
  }

  public componentDidMount() {
    this.updateDimensions();

    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  public render() {
    const { assets } = this.props;
    const { fullScreen } = this.state;

    return (
      <header
        className={`u-gutter hero-section ${
          fullScreen ? 'hero-section--fullscreen' : ''
        }`}
        style={
          assets.hero_image && !assets.hero_video
            ? { backgroundImage: `url(${assets.hero_image})` }
            : undefined
        }
      >
        <a className="hero-section__logo">
          <div className="skin-logo-size">
            <img className="hero-section__logo__img" src={assets.logo} alt={assets.logo_image_alt_text} />
          </div>
        </a>
        <span role="img" aria-label={assets.hero_image_alt_text}></span>
        <div className="hero-section__details" ref={this.setDetailsElement}>
          {assets.brand_name && (
            <p className="hero-section__details__overline u-type-label u-type-label--dark u-type-xs">
              {assets.brand_name}
            </p>
          )}
          <h1 className="hero-section__details__headline u-type-heading u-type-heading--dark u-type-heading--tight u-type-xxl">
            <span className="skin-heading-sizes">
              {removeWidowWord(assets.headline)}
            </span>
          </h1>
          <p className="hero-section__details__sub u-type-body u-type-body--dark u-type-md">
            {removeWidowWord(assets.subtitle)}
          </p>
          <ActionButton
            className="hero-section__details__action"
            onClick={this.smoothScrollIntoView}
          >
            Let's get started
          </ActionButton>
        </div>

        {this.renderHeroVideo()}
      </header>
    );
  }

  private setDetailsElement = (el: HTMLDivElement) => {
    this.detailsElement = el;
  };

  private updateDimensions() {
    if (this.detailsElement) {
      const detailsHeight = this.detailsElement.offsetHeight;

      if (window.innerHeight > detailsHeight) {
        this.setState({ fullScreen: true });
      } else {
        this.setState({ fullScreen: false });
      }
    }
  }

  private smoothScrollIntoView = (event: SyntheticEvent<EventTarget>) => {
    const node = document.getElementById(this.props.scrollTo);

    event.preventDefault();

    if (node) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  private isMp4 = (fileName: string) => {
    return path.extname(urlParse(fileName).pathname) === '.mp4';
  };

  private renderHeroVideo() {
    const { assets } = this.props;

    if (this.isMp4(assets.hero_video) && assets.hero_video) {
      return (
        <div className="hero-section__video">
          <video autoPlay muted loop className="hero-section__video__element">
            <source src={assets.hero_video} type="video/mp4" />
          </video>
        </div>
      );
    }
  }
}

export default HeroSection;
