import * as classNames from 'classnames';
import * as React from 'react';
import { IAssets } from '../../reducers/types/assets';
import { TransformedData } from '../../reducers/types/components';
import { removeWidowWord } from '../../utils';
import ActionButton from '../common/action-button';
import MarkdownContent from '../common/markdown-content';

export interface Props {
  assets: IAssets;
  data: TransformedData;
}

class VideoTextSection extends React.PureComponent<Props, {}> {
  public render() {
    const { data } = this.props;

    return (
      <section
        id={data.id}
        className={classNames(
          'image-text-section u-color-bg-snow',
          {
            'image-text-section--left': data.isLeft,
            'image-text-section--right': !data.isLeft,
          },
          data.css_identifier
        )}
      >
        {data.isLeft ? this.renderTextTile() : this.renderVideoTile()}
        {data.isLeft ? this.renderVideoTile() : this.renderTextTile()}
      </section>
    );
  }

  public componentDidMount() {
    const video = document.getElementsByClassName('embeddedVideoTag')

    if (video) {
      for (let i = 0; i < video.length; i++) {
        video[i].setAttribute("controlsList", "nodownload");
        video[i].setAttribute("disablePictureInPicture", "disablePictureInPicture");
      }
    }
  }

  private renderVideoTile = () => {
    const { data } = this.props;
    return (
      <div className="video-text-section__video-col">
          <video className="video-text-section__video embeddedVideoTag" controls poster={ data.thumbnail }>
            <source src={ data.video }/>
            Sorry, your browser doesn't support embedded videos.
          </video>
      </div>
    );
  };

  private renderTextTile = () => {
    const { data } = this.props;

    return (
      <div
        className={classNames({
          'image-text-section__text u-section-padding-sm u-gutter': true,
        })}
      >
        <div className="image-text-section__text__pos u-container-md">
          <h2 className="u-type-heading u-type-heading-margin u-type-xl">
            <span className="skin-heading-sizes">
              {removeWidowWord(data.title)}
            </span>
          </h2>

          <MarkdownContent content={data.content} />

          {data.button_copy && (
            <ActionButton
              onClick={this.onDownloadClick}
              className="image-text-section__action"
            >
              {data.button_copy}
            </ActionButton>
          )}
        </div>
      </div>
    );
  };

  private onDownloadClick = () => {
    const { data } = this.props;
    if (data.file) {
      window.open(data.file, '_blank');
    }
  };
}

export default VideoTextSection;
