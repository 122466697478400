import { GET_INITIAL_BRANDING_ASSETS } from '../actions';
import { IAction } from '../actions';
import { IAssets } from './types/assets';


const initialState = new Map<string, IAssets>();

export function assets(state = initialState, action: IAction<any, any>) {
  switch (action.type) {
    case GET_INITIAL_BRANDING_ASSETS.SUCCESS:
      for (const property in action.payload) {
        if (action.payload.hasOwnProperty(property)){
          const brand = action.payload[property];
          initialState.set("/" + brand.slug, Object.assign({}, brand, { components: undefined }));
        }
      }

      const defaultBrand = action.payload[0];
      initialState.set('/', defaultBrand);

      return new Map(initialState);

    case GET_INITIAL_BRANDING_ASSETS.FAILURE:
      return null;

    default:
      return state;
  }
}
