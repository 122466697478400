import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  getInitialBrandingLogos,
  loadBrandingLogos,
  updateInitialBrandingLogos,
} from '../../actions';
import { removeWidowWord } from '../../utils';

// Types
import { IAssets } from '../../reducers/types/assets';
import { TransformedData } from '../../reducers/types/components';
import { LogoState } from '../../reducers/types/logos';
import { IStore } from '../../store/types';

// Components
import ActionButton from '../common/action-button';
import BackButton from '../common/back-button';
import LoadingSpinner from '../common/loading-spinner';
import LogoCollection from '../common/logo-collection';
import LogoWizard from '../common/logo-wizard';
import MarkdownContent from '../common/markdown-content';

import { defaultLogoState } from '../../reducers/logos';

const COLLAPSED_LOGO_COUNT: number = 6;

export interface OwnProps {
  assets: IAssets;
  data: TransformedData;
}

interface StateProps {
  logos: Map<string, LogoState>;
}

interface LocalState {
  logosCollapsed: boolean;
}

interface DispatchProps {
  getInitialBrandingLogos: typeof getInitialBrandingLogos;
  loadBrandingLogos: typeof loadBrandingLogos;
  updateInitialBrandingLogos: typeof updateInitialBrandingLogos;
}

type Props = OwnProps & StateProps & DispatchProps & LocalState;

class LogosSection extends React.PureComponent<Props, LocalState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      logosCollapsed: true,
    };

    this.handleExpand = this.handleExpand.bind(this);
  }

  public componentDidMount() {
    const { assetbank_logo_category_id } = this.props.data;
    this.props.getInitialBrandingLogos(
      `/api/brand/folders/${assetbank_logo_category_id}/`,
      { assetbank_logo_category_id: this.props.data.assetbank_logo_category_id }
    );
  }

  public getLogos(props: Props): LogoState {
    return (
      props.logos.get(this.props.data.assetbank_logo_category_id) || {
        ...defaultLogoState,
      }
    );
  }

  public componentWillReceiveProps(nextProps: Props) {
    const logo = this.getLogos(nextProps);

    if (
      !logo.loading &&
      logo.folders !== null &&
      !logo.options.length &&
      logo.logos.length === 0
    ) {
      // Prevent re-render thrashing into oblivion by only making these requests if the data isn't loaded
      if (!logo.isLogoLoaded) {
        this.props.updateInitialBrandingLogos(
          { loading: true },
          {
            assetbank_logo_category_id: this.props.data
              .assetbank_logo_category_id,
          }
        );
        this.props.loadBrandingLogos(
          `/api/brand/folders/${logo.folders.id}/assets`,
          {
            assetbank_logo_category_id: this.props.data
              .assetbank_logo_category_id,
          }
        );
      }
    }
  }

  public render() {
    const { data: { id, title, content, css_identifier } } = this.props;

    const { isLogoLoaded, loading, isDataFound, logos } = this.getLogos(
      this.props
    );

    const { logosCollapsed } = this.state;
    const logosCollection = logos;

    return (
      <section
        className={classNames(
          'logos-section u-color-bg-iron u-gutter u-section-padding-md',
          { 'is-loading': loading },
          css_identifier
        )}
      >
        <div className="logos-section__details u-container-sm">
          <h2
            id={id}
            className="u-type-heading u-type-heading-margin u-type-xl u-type-centered"
          >
            <span className="skin-heading-sizes">{removeWidowWord(title)}</span>
          </h2>
          <MarkdownContent content={content} centeredText />
        </div>

        <div className="u-container-xl">
          <div className="logos-section__content skin-card-corners">
            {loading && <LoadingSpinner className="logos-section__spinner" />}

            {/* Back button */}
            {this.renderBackButton()}

            <div className="logos-section__content__main">
              {/* Question wizard */}
              {!isLogoLoaded &&
                isDataFound && <LogoWizard data={this.props.data} />}

              {/* Card list */}
              {isLogoLoaded &&
                logosCollection.length > 0 && (
                  <LogoCollection
                    logos={logosCollection}
                    expanded={logosCollapsed}
                  />
                )}

              {/* Logo card expansion */}
              {isLogoLoaded && this.renderExpandButton()}

              {/* No logos error */}
              {isLogoLoaded &&
                logosCollection.length === 0 &&
                this.renderNoLogosMessage()}
            </div>
          </div>
        </div>
      </section>
    );
  }

  private onPreviousClick = () => {
    const logo = this.getLogos(this.props);
    const { history } = logo;

    if (history.length > 1) {
      const previousHistory = history[history.length - 2];

      this.collapseLogos();

      this.props.updateInitialBrandingLogos(
        {
          question: previousHistory.description,
          options: previousHistory.options,
          history: history.slice(0, history.length - 1),
          isLogoLoaded: false,
          radioChecked: [],
        },
        {
          assetbank_logo_category_id: this.props.data
            .assetbank_logo_category_id,
        }
      );
    }
  };

  private renderBackButton() {
    const logo = this.getLogos(this.props);
    const { options, history } = logo;

    if (options.length !== 0 && history.length > 1) {
      return (
        <div className="logos-section__content__head">
          <BackButton
            onClick={this.onPreviousClick}
            text="Back"
            className="logos-section__back-btn"
          />
        </div>
      );
    }
  }

  private renderNoLogosMessage() {
    return (
      <h3 className="logos-section__no-logos u-type-heading u-type-lg u-type-centered">
        No logos meet this description
      </h3>
    );
  }

  private expandLogos() {
    this.setState({ logosCollapsed: false });
  }

  private collapseLogos() {
    this.setState({ logosCollapsed: true });
  }

  private handleExpand() {
    if (this.state.logosCollapsed) {
      this.expandLogos();
    } else {
      this.collapseLogos();
    }
  }

  private renderExpandButton() {
    const logo = this.getLogos(this.props);
    const sourceLogos = logo.logos;
    const { logosCollapsed } = this.state;

    if (sourceLogos.length > COLLAPSED_LOGO_COUNT) {
      return (
        <ActionButton
          onClick={this.handleExpand}
          full
          ghost
          className="logos-section__expand"
        >
          {logosCollapsed ? 'Show more' : 'Show less'}
        </ActionButton>
      );
    }
  }
}

const mapStateToProps = (
  { logos }: IStore,
  props: OwnProps
): StateProps & OwnProps => ({
  ...props,
  logos,
});

const mapDispatchToProps = {
  getInitialBrandingLogos,
  loadBrandingLogos,
  updateInitialBrandingLogos,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogosSection);
