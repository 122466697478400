import * as classNames from 'classnames';
import * as React from 'react';

export interface Props {
  className?: string;
}

class LoadingSpinner extends React.PureComponent<Props, {}> {
  public render() {
    const { className } = this.props;

    const classes = classNames('loading-spinner', className);

    return (
      <div className={classes}>
        <svg className="loading-spinner__inner" viewBox="0 0 50 50">
          <circle
            className="loading-spinner__path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          />
        </svg>
      </div>
    );
  }
}

export default LoadingSpinner;
