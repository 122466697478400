import { COLLAPSE_BY_ID, EXPAND_BY_ID } from '../actions';
import { IAction } from '../actions';

const initialState: Set<[string]> = new Set();

export function expanded(state = initialState, action: IAction<any, any>) {
  switch (action.type) {
    case COLLAPSE_BY_ID:
      state.delete(action.payload);
      return new Set(state.values());

    case EXPAND_BY_ID:
      return new Set(state.add(action.payload).values());

    default:
      return state;
  }
}
