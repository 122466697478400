import { GET_INITIAL_BRANDING_ASSETS } from '../actions';
import { IAction } from '../actions';
import { ComponentTypes } from './types/components';

const initialState = new Map<string, ComponentTypes[]>();

export function components(state = initialState, action: IAction<any, any>) {
  switch (action.type) {
    case GET_INITIAL_BRANDING_ASSETS.SUCCESS:
      for (const property in action.payload) {
        if (action.payload.hasOwnProperty(property)){
          const brand = action.payload[property];
          initialState.set("/" + brand.slug, brand.components);
        }
      }

      const defaultBrand = action.payload[0];
      initialState.set('/', defaultBrand.components);

      return new Map(initialState);

    case GET_INITIAL_BRANDING_ASSETS.FAILURE:
      return null;

    default:
      return state;
  }
}
