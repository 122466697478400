import { Collapse } from '@dabapps/roe';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { collapseById, expandById } from '../../actions';
import { IAssets } from '../../reducers/types/assets';
import { TransformedData } from '../../reducers/types/components';
import { IStore } from '../../store/types';
import ActionButton from '../common/action-button';
import MarkdownContent from '../common/markdown-content';

export const MAX_COLLAPSE_HEIGHT: number = 600;

export interface OwnProps {
  assets: IAssets;
  data: TransformedData;
}

interface DispatchProps {
  expandById: typeof expandById;
  collapseById: typeof collapseById;
}

interface StateProps {
  expanded: Set<string>;
}

type Props = OwnProps & StateProps & DispatchProps;

class TextSection extends React.PureComponent<Props, {}> {
  public render() {
    const { data } = this.props;

    return (
      <section
        className={classNames(
          'text-section u-color-bg-pure u-gutter u-section-padding-md',
          this.isExpanded() ? 'is-open' : 'is-closed',
          data.css_identifier
        )}
      >
        <div className="u-container-md">
          {data.truncate ? (
            <div>
              <Collapse
                open={this.isExpanded() || false}
                maxCollapsedHeight={MAX_COLLAPSE_HEIGHT}
                fadeOut
                fadeColor="#FFF"
                className="text-section__collapse"
                fadeHeight={50}
                animationDuration={200}
              >
                <MarkdownContent content={data.content} />
              </Collapse>

              <ActionButton
                onClick={this.toggleExpandable}
                full
                ghost
                className="text-section__action"
              >
                {this.isExpanded() ? 'Show less' : 'Show more'}
              </ActionButton>
            </div>
          ) : (
            <MarkdownContent content={data.content} />
          )}
        </div>
      </section>
    );
  }

  private toggleExpandable = () => {
    const { expanded, data } = this.props;
    const expandableId = data.id;

    if (expanded.has(expandableId)) {
      this.props.collapseById(expandableId);
    } else {
      this.props.expandById(expandableId);
    }
  };

  private isExpanded = () => {
    const { expanded, data } = this.props;

    return expanded.has(data.id);
  };
}

const mapStateToProps = (
  { expanded }: IStore,
  props: OwnProps
): StateProps & OwnProps => ({
  ...props,
  expanded,
});

const mapDispatchToProps = {
  expandById,
  collapseById,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextSection);
