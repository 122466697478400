import * as classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface Props {
  pageSlug: string;
  title: string;
  onCollapseSidebar: () => void;
}

export interface State {
  isOpen: boolean;
}

class Accordian extends React.PureComponent<Props, State> {
  
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  toggleNavigation = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  public render() {
    return (
      <div className="menu-item menu-accordian">
        <label className="menu-accordian__label u-type-heading skin-heading-sizes">
          <span className="menu-accordian__toggle" onClick={this.toggleNavigation}>
             <span 
              className={classNames(
              'menu-accordian__toggle__dash',
              this.state.isOpen && 'is-open'
              )}>
            </span>
            <span
              className={classNames(
              'menu-accordian__toggle__dash menu-accordian__toggle__dash--second',
              this.state.isOpen && 'is-open'
              )}>
            </span>
          </span>
          <Link to={'/' + this.props.pageSlug} onClick={() => {this.props.onCollapseSidebar()}} className="menu-accordian__title">
            {this.props.title}
          </Link>
        </label>
        {this.state.isOpen &&
          <div className="menu-item">
            {this.props.children}
          </div>
        }
      </div>
    );
  }
}

export default Accordian;
