import * as classNames from 'classnames';
import * as React from 'react';
import { COPY_TO_CLIPBOARD_TIME_OUT } from '../../../utils';
import ColorSwatchCopied from './color-swatch-copied';
import ColorSwatchFormats from './color-swatch-formats';

interface Props {
  name: string;
  hex: string;
  cmyk?: string;
  rgb?: string;
  pantone?: string;
}

export interface LocalState {
  copied: boolean;
  swatchHovered: boolean;
  copyValue: string;
}

class ColorSwatch extends React.PureComponent<Props, LocalState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      copied: false,
      swatchHovered: false,
      copyValue: '',
    };

    this.showCopyConfirm = this.showCopyConfirm.bind(this);
    this.updateCopyValue = this.updateCopyValue.bind(this);
  }

  public render() {
    const { name, hex, cmyk, rgb, pantone } = this.props;
    const { swatchHovered, copied, copyValue } = this.state;
    const isDarkMode = this.isDarkMode(hex);

    return (
      <div
        className={classNames(
          'color-swatch',
          !isDarkMode && 'color-swatch--light'
        )}
        onMouseEnter={this.onSwatchEnter}
        onMouseLeave={this.onSwatchLeave}
      >
        <div
          className="color-swatch__pos skin-card-corners"
          style={{ backgroundColor: `#${hex}` }}
        >
          <div className="color-swatch__initial">
            <h3
              className={classNames(
                'u-type-body u-type-body--strong u-type-sm color-swatch__name',
                isDarkMode && 'u-type-body--dark',
                (swatchHovered || copied) && 'is-hidden'
              )}
            >
              {name}
            </h3>
          </div>

          <ColorSwatchCopied
            visible={copied}
            backgroundColor={hex}
            copiedValue={copyValue}
            darkMode={isDarkMode}
          />

          <ColorSwatchFormats
            colors={{ hex, cmyk, rgb, pantone }}
            darkMode={isDarkMode}
            visible={swatchHovered && !copied}
            showCopyConfirm={this.showCopyConfirm}
            updateCopyValue={this.updateCopyValue}
          />
        </div>
      </div>
    );
  }

  public hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    hex = hex.replace(shorthandRegex, (m, r: string, g: string, b: string) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;

    return rgb;
  }

  public isDarkMode(hex: string) {
    const rgb = this.hexToRgb(hex);

    if (rgb) {
      const hsp = Math.sqrt(
        0.299 * (rgb.r * rgb.r) +
          0.587 * (rgb.g * rgb.g) +
          0.114 * (rgb.b * rgb.b)
      );

      if (hsp < 200) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  private showCopyConfirm() {
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, COPY_TO_CLIPBOARD_TIME_OUT);
  }

  private updateCopyValue(value: string) {
    this.setState({ copyValue: value });
  }

  private onSwatchEnter = () => {
    this.setState({ swatchHovered: true });
  };

  private onSwatchLeave = () => {
    this.setState({ swatchHovered: false });
  };
}

export default ColorSwatch;
