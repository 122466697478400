import * as classNames from 'classnames';
import * as React from 'react';
import { IAssets } from '../../reducers/types/assets';
import { TransformedData } from '../../reducers/types/components';
import { removeWidowWord } from '../../utils';
import ActionButton from '../common/action-button';
import MarkdownContent from '../common/markdown-content';

export interface Props {
  assets: IAssets;
  data: TransformedData;
}

class ImageTextSection extends React.PureComponent<Props, {}> {
  public render() {
    const { data } = this.props;

    return (
      <section
        id={data.id}
        className={classNames(
          'image-text-section u-color-bg-snow',
          {
            'image-text-section--left': data.isLeft,
            'image-text-section--right': !data.isLeft,
          },
          data.css_identifier
        )}
      >
        {data.isLeft ? this.renderTextTile() : this.renderImageTile()}
        {data.isLeft ? this.renderImageTile() : this.renderTextTile()}
      </section>
    );
  }

  private renderImageTile = () => {
    const { data } = this.props;
    return (
      <div
        className="image-text-section__image"
      >
        <img className="image-text-section__image__image" alt={data.image_alt_text} src={data.image}/>
        <div className="image-text-section__image__min-holder" />
      </div>
    );
  };

  private renderTextTile = () => {
    const { data } = this.props;

    return (
      <div
        className={classNames({
          'image-text-section__text u-section-padding-md u-gutter': true,
        })}
      >
        <div className="image-text-section__text__pos u-container-md">
          <h2 className="u-type-heading u-type-heading-margin u-type-xl">
            <span className="skin-heading-sizes">
              {removeWidowWord(data.title)}
            </span>
          </h2>

          <MarkdownContent content={data.content} />

          {data.button_copy && (
            <ActionButton
              onClick={this.onDownloadClick}
              className="image-text-section__action"
            >
              {data.button_copy}
            </ActionButton>
          )}
        </div>
      </div>
    );
  };

  private onDownloadClick = () => {
    const { data } = this.props;
    if (data.file) {
      window.open(data.file, '_blank');
    }
  };
}

export default ImageTextSection;
