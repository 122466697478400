import * as classNames from 'classnames';
import * as React from 'react';
import { SyntheticEvent } from 'react';

export interface Props {
  className?: string;
  full?: boolean;
  onClick?: (event: SyntheticEvent<EventTarget>) => void;
  href?: string;
  component?: string;
  alignLeft?: boolean;
  secondary?: boolean;
  ghost?: boolean;
  chunky?: boolean;
}

class ActionButton extends React.PureComponent<Props, {}> {
  public render() {
    const {
      children,
      onClick,
      className,
      full,
      href,
      component: Component = href ? 'a' : 'button',
      alignLeft,
      secondary,
      chunky,
      ghost,
    } = this.props;

    const classes = classNames(
      'u-type-body action-button u-type-sm skin-button-corners',
      className,
      full && 'action-button--full',
      alignLeft && 'action-button--left',
      !(secondary || ghost) && 'action-button--primary',
      secondary && 'action-button--secondary',
      ghost && 'action-button--ghost',
      chunky && 'action-button--chunky'
    );

    return (
      <Component onClick={onClick} className={classes} href={href}>
        <span className="action-button__children">{children}</span>
        <span className="action-button__overlay skin-button-corners" />
      </Component>
    );
  }
}

export default ActionButton;
