import { AxiosResponse } from 'axios';
import axios, { AxiosPromise } from 'axios';
import * as path from 'path';
import { IStore } from './store/types';

export type UrlMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH';

export type GetStateCallback = () => IStore;
export type TActionAny = IAction<any, any>;
export type DispatchCallback = (action: TActionAny | AsyncAction) => void;
export type Thunk<T> = (
  dispatch: DispatchCallback,
  getState: GetStateCallback
) => Promise<T>;
export type AsyncAction =
  | Thunk<AxiosResponse | null>
  | Promise<AxiosResponse | null>;

export interface IAction<PayloadT, MetaT> {
  type: string;
  payload?: PayloadT;
  error?: boolean;
  meta?: MetaT;
}

export interface IAsyncActionSet {
  FAILURE: string;
  REQUEST: string;
  SUCCESS: string;
}

export function makeAsyncActionSet(
  actionName: string
): Readonly<IAsyncActionSet> {
  return {
    FAILURE: actionName + '_FAILURE',
    REQUEST: actionName + '_REQUEST',
    SUCCESS: actionName + '_SUCCESS',
  };
}

export const constructHeaders = (headers = {}) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  ...headers,
});

export function apiRequest(
  url: string,
  method: string,
  data = {},
  headers = {},
  onUploadProgress?: (event: ProgressEvent) => void
): AxiosPromise {
  return axios({
    method,
    url: path.normalize(url),
    data,
    headers: constructHeaders(headers),
    onUploadProgress,
  });
}

export function removeWidowWord(str: string) {
  return str ? str.replace(/\s(?=[^\s]*$)/g, '\u00a0') : null;
}

export const MAX_COLLAPSED_HEIGHT: number = 378;

export const COPY_TO_CLIPBOARD_TIME_OUT: number = 1000;

export const ID_COLLECTION = {
  SIDEBAR: 'SIDEBAR',
};

export function getBrandSlug() {
  const elem = document.getElementById('app');
  return elem && elem.getAttribute('data-brand-slug');
}
