import * as classNames from 'classnames';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { collapseById, expandById } from '../../actions';
import { IAssets } from '../../reducers/types/assets';
import { ComponentTypes } from '../../reducers/types/components';
import { ID_COLLECTION } from '../../utils';

import MenuItem from './menu-item';
import Accordian from './menu-accordian';
import InternalMenuItem from "./internal-menu-item";

interface StateProps {
  expanded: Set<string>;
  assets: Map<string, IAssets>;
  components: Map<string, ComponentTypes[]>;
}

interface LocalStateProps {
  listInteracting: boolean;
}

interface DispatchProps {
  expandById: typeof expandById;
  collapseById: typeof collapseById;
}

type Props = DispatchProps & StateProps;

const EXCLUDE_NAVIGATION_ITEMS: Set<string> = new Set(['FOOTER']);

class Menu extends React.PureComponent<Props, LocalStateProps> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      listInteracting: false,
    };
  }

  public render() {
    return (
      <div
        className={classNames(
          'menu',
          this.isExpanded() ? 'is-open' : 'is-closed'
        )}
      >
        <div className="menu__sidebar">
          <div className="menu__sidebar__overflow">
            <nav className="menu__sidebar__nav">
              <div
                onMouseEnter={this.listIsInteracting}
                onMouseLeave={this.listIsIdle}
                onFocus={this.listIsInteracting}
                onBlur={this.listIsIdle}
              >
                {this.renderMenu()}
              </div>
            </nav>
          </div>
        </div>
        <div className="menu__scrim" onClick={this.collapseSidebar} />
      </div>
    );
  }

  // List rendering
  private renderMenu() {
    const assets  = this.props.assets || {};
    const components  = this.props.components || {};

    if (assets && components) {
      return Array.from(assets.keys()).filter(this.removeDefaultAssetKey).map((key, index) => {
        const brandPageAssets = assets.get(key);
        const brandPageComponents = components.get(key);
        const hasMultipleBrands = assets.size > 2;

        if (brandPageAssets && brandPageComponents){
          if (hasMultipleBrands === true) {
            return (
              <Accordian onCollapseSidebar={() => {this.props.collapseById(ID_COLLECTION.SIDEBAR)}} pageSlug={brandPageAssets.slug} key={key} title={brandPageAssets.brand_name}>
                {this.renderNavigationItems(brandPageComponents, brandPageAssets.slug)}
                {this.renderImagesLink(brandPageComponents, brandPageAssets.slug)}
              </Accordian>
            );
          } else {
            return (
              <div className="menu-item">
                {this.renderNavigationItems(brandPageComponents, brandPageAssets.slug)}
                {this.renderImagesLink(brandPageComponents, brandPageAssets.slug)}
              </div>
            );
          }
        }
      });
    }
  }

  // List rendering
  private getFilteredNavItems(listItemComponents: ComponentTypes[]) {
    return listItemComponents.filter(
      (component: ComponentTypes) =>
        !EXCLUDE_NAVIGATION_ITEMS.has(component.type) && component.title
    );
  }

  private removeDefaultAssetKey(assetKey: string){
    return assetKey != '/';
  }

  private renderNavigationItems(listItemComponents: ComponentTypes[], slug: string) {
    const navigationItems = this.getFilteredNavItems(listItemComponents).map(
      (item: ComponentTypes, index) => {
        return (
          <InternalMenuItem
            delay={this.getAnimationDelay(index)}
            open={this.isExpanded()}
            href={`/${slug}#${item.id}`}
            key={item.id}
            onClick={this.smoothScrollIntoView.bind(this, item.id)}
            standby={this.state.listInteracting}
            onCollapseSidebar={() => {this.props.collapseById(ID_COLLECTION.SIDEBAR)}}
          >
            {item.title}
          </InternalMenuItem>
        );
      }
    );

    return navigationItems;
  }

  // Custom link rendering
  private renderImagesLink(listItemComponents: ComponentTypes[], slug: string) {
    const assets = this.props.assets.get(slug);

    if (assets) {
      const {
        assetbank_images_url,
        assetbank_images_url_label,
      } = assets;

      if (assetbank_images_url && assetbank_images_url_label) {
        const transitionDelay = this.getAnimationDelay(
            this.getFilteredNavItems(listItemComponents).length
        );

        return (
            <MenuItem
                delay={transitionDelay}
                open={this.isExpanded()}
                href={assetbank_images_url}
                standby={this.state.listInteracting}
            >
              {assetbank_images_url_label}
            </MenuItem>
        );
      }
    }
  }

  // List interaction and animation
  private getAnimationDelay(index: number) {
    return index / 60;
  }

  private listIsInteracting = () => {
    this.setState({ listInteracting: true });
  };

  private listIsIdle = () => {
    this.setState({ listInteracting: false });
  };

  // Open and close state/s
  private isExpanded = () => {
    const { expanded } = this.props;
    return expanded.has(ID_COLLECTION.SIDEBAR);
  };

  private collapseSidebar = () => {
    this.props.collapseById(ID_COLLECTION.SIDEBAR);
  };


  // View scrolling
  private smoothScrollIntoView = (
    href: string,
    event: SyntheticEvent<EventTarget>
  ) => {
    const node = document.getElementById(href);

    event.preventDefault();

    if (node) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    this.collapseSidebar();
  };
}

const mapStateToProps = (
  { expanded, assets, components }: StateProps,
): StateProps => ({
  expanded, assets, components
});

const mapDispatchToProps = {
  expandById,
  collapseById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
