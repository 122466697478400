import { LogoState } from '../reducers/types/logos';
import {
  apiRequest,
  DispatchCallback,
  GetStateCallback,
  IAsyncActionSet,
  makeAsyncActionSet,
  UrlMethod,
} from '../utils';

export interface IAction<PayloadT, MetaT> {
  type: string;
  payload?: PayloadT;
  error?: boolean;
  meta?: MetaT;
}

export function dispatchGenericRequest(
  actionSet: IAsyncActionSet,
  url: string,
  method: UrlMethod,
  data?: any
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    dispatch({
      type: actionSet.REQUEST,
      payload: { preserveOriginal: true, ...data },
    });

    return apiRequest(url, method, data)
      .then(response => {
        dispatch({
          type: actionSet.SUCCESS,
          payload: { ...response.data, ...data },
        });
        return response;
      })
      .catch(error => {
        const errorData = error && error.response && error.response.data;

        dispatch({
          type: actionSet.FAILURE,
          payload: { ...errorData, ...data },
        });
        return Promise.reject(error);
      });
  };
}

export const GET_INITIAL_BRANDING_ASSETS = makeAsyncActionSet(
  'GET_INITIAL_BRANDING_ASSETS'
);

export function getInitialBrandingAssets(endpoint: string) {
  return dispatchGenericRequest(GET_INITIAL_BRANDING_ASSETS, endpoint, 'GET');
}

export const GET_INITIAL_BRANDING_LOGOS = makeAsyncActionSet(
  'GET_INITIAL_BRANDING_LOGOS'
);

export function getInitialBrandingLogos(endpoint: string, data: any) {
  return dispatchGenericRequest(
    GET_INITIAL_BRANDING_LOGOS,
    endpoint,
    'GET',
    data
  );
}

export const LOAD_BRANDING_LOGOS = makeAsyncActionSet('LOAD_BRANDING_LOGOS');

export function loadBrandingLogos(endpoint: string, data: any) {
  return dispatchGenericRequest(LOAD_BRANDING_LOGOS, endpoint, 'GET', data);
}

export const UPDATE_INITIAL_BRANDING_LOGOS = 'UPDATE_INITIAL_BRANDING_LOGOS';

export function updateInitialBrandingLogos(
  object: Partial<LogoState>,
  data: any
) {
  return {
    type: UPDATE_INITIAL_BRANDING_LOGOS,
    payload: { ...object, ...data },
  };
}

export const EXPAND_BY_ID = 'EXPAND_BY_ID';
export function expandById(id: string) {
  return {
    type: EXPAND_BY_ID,
    payload: id,
  };
}
export const COLLAPSE_BY_ID = 'COLLAPSE_BY_ID';
export function collapseById(id: string) {
  return {
    type: COLLAPSE_BY_ID,
    payload: id,
  };
}

export const SET_BRAND_SLUG = 'SET_BRAND_SLUG';
export function setBrandSlug(slug: string) {
  return {
    type: SET_BRAND_SLUG,
    payload: slug,
  };
}
