import * as classNames from 'classnames';
import * as React from 'react';
import ColorSwatchTick from './color-swatch-tick';

export interface Props {
  visible: boolean;
  backgroundColor: string;
  copiedValue: string;
  darkMode?: boolean;
}

class ColorSwatchCopied extends React.PureComponent<Props, {}> {
  public render() {
    const { visible, backgroundColor, copiedValue, darkMode } = this.props;

    const wrapperClasses = classNames(
      'color-swatch-copied',
      visible && 'is-visible'
    );

    const headingClasses = classNames(
      'u-type-heading u-type-md u-type-centered color-swatch-copied__heading',
      darkMode && 'u-type-heading--dark'
    );

    const valueClasses = classNames(
      'u-type-label u-type-xs u-type-centered color-swatch-copied__value',
      darkMode && 'u-type-label--dark'
    );

    return (
      <div
        className={wrapperClasses}
        style={{ backgroundColor: `#${backgroundColor}` }}
      >
        <div className="color-swatch-copied__pos">
          <div className="color-swatch-copied__icon">
            <ColorSwatchTick darkMode={darkMode} visible={visible} />
          </div>

          <h4 className={headingClasses}>Copied to clipboard!</h4>

          <div className={valueClasses}>{copiedValue}</div>
        </div>
      </div>
    );
  }
}

export default ColorSwatchCopied;
