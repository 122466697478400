import { GET_INITIAL_BRANDING_ASSETS, SET_BRAND_SLUG } from '../actions';
import { IAction } from '../actions';
import { IAppState } from './types/app-state';

const initialState: IAppState = {
  location: '/',
  loading: true,
  error: null,
};

export function appState(state = initialState, action: IAction<any, any>) {
  switch (action.type) {
    case GET_INITIAL_BRANDING_ASSETS.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case GET_INITIAL_BRANDING_ASSETS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_BRAND_SLUG:
      return {
        ...state,
        slug: action.payload,
      };

    default:
      return state;
  }
}
