import * as classNames from 'classnames';
import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';

export interface Props {
  content: string;
  className?: string;
  small?: boolean;
  centeredText?: boolean;
  dark?: boolean;
}

class MarkdownContent extends React.PureComponent<Props, {}> {
  public render() {
    const { className, content, centeredText, small, dark } = this.props;

    return (
      <ReactMarkdown
        className={classNames(
          'markdown-content',
          className,
          centeredText && 'markdown-content--centered',
          small && 'markdown-content--sm',
          dark && 'markdown-content--dark'
        )}
        source={content}
        renderers={{ heading: this.HeadingRenderer }}
      />
    );
  }

  // Improve page semantics by replacing h1 with h2
  private HeadingRenderer = (props: any) => {
    if (props.level === 1) {
      return (
        <h2>
          <span className="skin-heading-sizes">{props.children}</span>
        </h2>
      );
    }

    const Heading = ReactMarkdown.renderers.heading;
    return <Heading {...props} />;
  };
}

export default MarkdownContent;
