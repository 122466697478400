import * as React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { getInitialBrandingAssets } from './actions';
import BrandPage from './components/pages/brandpage';
import { connect, ConnectedProps } from 'react-redux';
import { IAppState } from '^/reducers/types/app-state';
import { IAssets } from '^/reducers/types/assets';
import { ComponentTypes } from '^/reducers/types/components';
import { IStore } from '^/store/types';
import ScrollToTopOnMount from "./scroll";

interface StateProps {
  appState: IAppState;
  assets: Map<string, IAssets>;
  components: Map<string, ComponentTypes[]>;
}

const mapStateToProps = ({ appState, assets, components, }: IStore): StateProps => ({
  appState,
  assets,
  components,
});

const mapDispatchToProps = {
  getInitialBrandingAssets,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class App extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.getInitialBrandingAssets('/api/brand');
  }

  public render() {
    return (
      <HashRouter>
        <Switch>
          {this.renderRoutes()}
        </Switch>
      </HashRouter>
    );
  }

  private renderRoutes() {
    const assets = this.props.assets;
    const routes = Array.from(assets.keys()).map(
      (value) => {
        return <Route key={value} path={value} component={BrandPage}/> ;
      });
    return routes
  }
}

// export default App;
export default connector(App);
