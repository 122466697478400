import * as React from 'react';
import { Logo } from '../../reducers/types/logos';
import LogoCard from '../common/logo-card';

const COLLAPSED_LOGO_COUNT: number = 6;

interface Props {
  logos: Logo[];
  expanded: boolean;
}

interface LocalState {
  logosCollapsed: boolean;
}

class LogoCollection extends React.PureComponent<Props, LocalState> {
  public render() {
    const logosCollection = this.props.logos;
    const logosToRender = this.props.expanded
      ? logosCollection.slice(0, COLLAPSED_LOGO_COUNT)
      : logosCollection;

    return (
      <div>
        <div className="logos-section__grid">
          {logosCollection &&
            logosToRender.map((logo: Logo, index) => (
              <div key={logo.id} className="logos-section__grid__col">
                <LogoCard
                  name={logo.name}
                  logo={logo.preview_url}
                  onClick={this.onDownloadClick(logo.id)}
                  delay={this.getLogoDelay(index)}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }

  private onDownloadClick = (assetId: number) => () => {
    window.open(`/api/brand/proxy/${assetId}/`, '_self');
  };

  private getLogoDelay(index: number) {
    return (
      (index > COLLAPSED_LOGO_COUNT - 1
        ? index - COLLAPSED_LOGO_COUNT
        : index) / 20
    );
  }
}

export default LogoCollection;
