import * as classNames from 'classnames';
import * as React from 'react';
import { IAssets } from '../../reducers/types/assets';
import { TransformedData } from '../../reducers/types/components';
import MarkdownContent from '../common/markdown-content';

export interface Props {
  assets: IAssets;
  data: TransformedData;
}

class FooterSection extends React.PureComponent<Props, {}> {
  public render() {
    const { data } = this.props;

    return (
      <section
        className={classNames(
          'footer-section u-section-padding-sm u-gutter u-color-bg-carbon',
          data.css_identifier
        )}
      >
        <MarkdownContent content={data.content} small centeredText dark />
      </section>
    );
  }
}

export default FooterSection;
