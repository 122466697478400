import * as classNames from 'classnames';
import * as React from 'react';
import { IAssets } from '../../reducers/types/assets';
import { BrandColor, TransformedData } from '../../reducers/types/components';
import { removeWidowWord } from '../../utils';
import ColorSwatch from '../common/color-swatch/color-swatch';
import MarkdownContent from '../common/markdown-content';

export interface Props {
  assets: IAssets;
  data: TransformedData;
}

class ColorsSection extends React.PureComponent<Props, {}> {
  public render() {
    const { data } = this.props;

    return (
      <section
        className={classNames(
          'colors-section u-gutter u-section-padding-md u-color-bg-pure',
          data.css_identifier
        )}
        id={data.id}
      >
        <div className="u-container-sm colors-section__details">
          <h2 className="u-type-heading u-type-centered u-type-heading-margin u-type-xl">
            <span className="skin-heading-sizes">
              {removeWidowWord(data.title)}
            </span>
          </h2>
          <MarkdownContent content={data.content} centeredText />
        </div>

        <div className="u-container-lg">
          <div className="colors-section__grid">
            {data.colors.map((color: BrandColor, index: number) => (
              <div key={index} className="colors-section__grid__col">
                <ColorSwatch
                  name={color.name}
                  hex={color.hex}
                  cmyk={color.cmyk}
                  pantone={color.pantone}
                  rgb={color.rgb}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default ColorsSection;
