import { TransformedData } from '^/reducers/types/components';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  getInitialBrandingLogos,
  loadBrandingLogos,
  updateInitialBrandingLogos,
} from '../../actions';
import { defaultLogoState } from '../../reducers/logos';
import { Entry, LogoState } from '../../reducers/types/logos';
import { IStore } from '../../store/types';
import { removeWidowWord } from '../../utils';
import ActionButton from '../common/action-button';

export const RADIO_OPTION_NOT_SELECTED: number = -1;

interface OwnProps {
  data: TransformedData;
}

interface StateProps {
  logos: Map<string, LogoState>;
}

interface DispatchProps {
  getInitialBrandingLogos: typeof getInitialBrandingLogos;
  loadBrandingLogos: typeof loadBrandingLogos;
  updateInitialBrandingLogos: typeof updateInitialBrandingLogos;
}

type Props = StateProps & DispatchProps & OwnProps;

class LogoWizard extends React.PureComponent<Props, {}> {
  public getLogos(props: Props): LogoState {
    return (
      props.logos.get(this.props.data.assetbank_logo_category_id) || {
        ...defaultLogoState,
      }
    );
  }

  public render() {
    const { question, options } = this.getLogos(this.props);

    const questionAvailable = question !== '';

    const wrapperClasses = classNames(
      'logos-section__wizard',
      !questionAvailable && 'logos-section__wizard--no-question'
    );

    return (
      <div className={wrapperClasses}>
        {questionAvailable && (
          <div className="logos-section__wizard__col logos-section__wizard__col--question">
            <h3
              className="u-type-heading u-type-lg logos-section__wizard__question"
              key={question}
            >
              <span className="skin-heading-sizes">
                {removeWidowWord(question)}
              </span>
            </h3>
          </div>
        )}

        <div className="logos-section__wizard__col logos-section__wizard__col--answers">
          <ul className="logos-section__wizard__answers">
            {this.renderAnswers(options, questionAvailable)}
          </ul>
        </div>
      </div>
    );
  }

  private goToNextPage = (selectedID: number) => {
    const { history } = this.getLogos(this.props);

    if (history.length > 0) {
      history[history.length - 1].options.forEach(entry => {
        if (selectedID > RADIO_OPTION_NOT_SELECTED && entry.id === selectedID) {
          if (entry.options.length > 0) {
            this.props.updateInitialBrandingLogos(
              {
                history: [...history, entry],
                question: entry.description,
                options: entry.options,
              },
              {
                assetbank_logo_category_id: this.props.data
                  .assetbank_logo_category_id,
              }
            );
          } else {
            this.props.updateInitialBrandingLogos(
              { loading: true },
              {
                assetbank_logo_category_id: this.props.data
                  .assetbank_logo_category_id,
              }
            );
            this.props.loadBrandingLogos(
              `/api/brand/folders/${selectedID}/assets`,
              {
                assetbank_logo_category_id: this.props.data
                  .assetbank_logo_category_id,
              }
            );
          }
        }
      });
    }
  };

  private onAnswerSelect = (option: Entry) => {
    const selectedID = option.id;
    const { radioChecked } = this.getLogos(this.props);

    radioChecked[selectedID] = true;

    this.props.updateInitialBrandingLogos(
      {
        radioChecked: [...radioChecked],
        selectedID,
      },
      { assetbank_logo_category_id: this.props.data.assetbank_logo_category_id }
    );

    this.goToNextPage(selectedID);
  };

  private renderAnswers(options: Entry[], questionAvailable: boolean) {
    const answers = options.map((option, index) => {
      const handleClick = () => this.onAnswerSelect(option);

      // Add an extra delay if there's a question
      // This staggers the answer items behind the heading for just a moment, enough to draw the eye left to right
      const delayFactor = 30;
      const delay = questionAvailable
        ? index / delayFactor + 0.05
        : index / delayFactor;
      const listNumber = (
        <span className="section__wizard__answer__number">{index + 1}.</span>
      );

      return (
        <li
          key={option.id}
          onClick={handleClick}
          className="logos-section__wizard__answers__item"
          style={{ animationDelay: `${delay}s` }}
        >
          <ActionButton
            full
            alignLeft={questionAvailable}
            secondary
            chunky
            className="logos-section__wizard__answer"
          >
            {questionAvailable && listNumber}
            {option.name}
          </ActionButton>
        </li>
      );
    });

    return answers;
  }
}

// what's the right state for this?
const mapStateToProps = (
  { logos }: IStore,
  data: OwnProps
): StateProps & OwnProps => ({
  logos,
  ...data,
});

const mapDispatchToProps = {
  getInitialBrandingLogos,
  loadBrandingLogos,
  updateInitialBrandingLogos,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoWizard);
